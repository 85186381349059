var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"home",attrs:{"role":"main"}},[_c('div',{staticClass:"main__header"},[_c('div',{staticClass:"main__header__item left"}),_c('div',{staticClass:"main__header__item"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"main__header__item right"})]),_c('div',{staticClass:"main__header"},[_c('div',{staticClass:"toper__item left"}),_c('div',{staticClass:"toper__item"},[_c('div',{staticClass:"button-group"},[_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'today' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('today')}}},[_c('span',[_vm._v("Сегодня")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'tomorrow' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('tomorrow')}}},[_c('span',[_vm._v("Вчера")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'week' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('week')}}},[_c('span',[_vm._v("Неделя")])]),_c('button',{class:{ active: _vm.$store.state.data.trades.sort2 === 'month' },attrs:{"type":"button"},on:{"click":function($event){return _vm.setSortType('month')}}},[_c('span',[_vm._v("Месяц")])]),_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.vcoConfig),expression:"vcoConfig"}],ref:"buttonPeriod",staticClass:"widgets__table__field",class:{
            active:
              _vm.$store.state.data.trades.sort2 === 'period' &&
              _vm.$store.state.data.trades.sortDate,
          },attrs:{"type":"button"},on:{"click":function($event){_vm.openPeriod = true}}},[_c('i',[_c('CalendarIcon')],1),_c('span',[_vm._v("Период")]),_c('b',{directives:[{name:"show",rawName:"v-show",value:(_vm.openPeriod),expression:"openPeriod"}],staticClass:"date-picker"},[_c('date-picker',{ref:"pickerPeriod",attrs:{"inline":"","range":""},model:{value:(_vm.$store.state.data.trades.sortDate),callback:function ($$v) {_vm.$set(_vm.$store.state.data.trades, "sortDate", $$v)},expression:"$store.state.data.trades.sortDate"}})],1)])])]),_c('div',{staticClass:"toper__item right"})]),_c('div',{staticClass:"main__header main__header--secondary"},[_c('div',{staticClass:"tbl"},[(_vm.$store.state.data.trades.showFilter)?_c('div',{staticClass:"tbl__header"},[_c('div',{staticClass:"tbl__filter"},[_c('label',{staticClass:"tbl__filter__item"},[_c('v-select',{attrs:{"appendToBody":"","options":_vm.filterDropName.map(function (item) { return ({
                  value: item.id,
                  label: item.name,
                }); }),"placeholder":"Сотрудник","value":_vm.filter.responsible_user_id
                  ? {
                      value: _vm.filter.responsible_user_id,
                      label: _vm.filterDropName.find(
                        function (s) { return s.id === _vm.filter.responsible_user_id; }
                      ).name,
                    }
                  : null},on:{"input":_vm.onUserChange},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('div',_vm._b({},'div',attributes,false),[_c('DownIcon')],1)]}}],null,false,3765746126)})],1),_c('label',{staticClass:"tbl__filter__item"},[_c('v-select',{attrs:{"appendToBody":"","options":_vm.filterDropGroup.map(function (item) { return ({
                  value: item.id,
                  label: item.name,
                }); }),"placeholder":"Подразделение","value":_vm.filter.group_id
                  ? {
                      value: _vm.filter.group_id,
                      label: _vm.filterDropGroup.find(
                        function (s) { return s.id === _vm.filter.group_id; }
                      ).name,
                    }
                  : null},on:{"input":_vm.onGroupChange},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('div',_vm._b({},'div',attributes,false),[_c('DownIcon')],1)]}}],null,false,3765746126)})],1)])]):_vm._e()])]),_c('div',{staticClass:"main__content"},[(_vm.error)?_c('div',{staticClass:"messages apollo"},[(_vm.error)?_c('div',{staticClass:"messages__status error"},[_vm._v("Ошибка загрузки данных. Обратитесь в тех. поддержку")]):_vm._e()]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"messages apollo"},[(_vm.isLoading)?_c('div',{staticClass:"messages__status"},[_vm._v("Загрузка...")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"google-charts"},[_c('div',{staticClass:"google-charts__item"},[(_vm.googleChart.dataSumm.length)?_c('GoogleChart',{attrs:{"type":'BarChart',"data":[['Статус', 'Сумма текущий год', 'Сумма предыдущий год'] ].concat( _vm.googleChart.dataSumm),"options":_vm.googleChart.optionsSumm}}):_vm._e()],1),_c('div',{staticClass:"google-charts__item"},[(_vm.googleChart.data.length)?_c('GoogleChart',{attrs:{"type":'BarChart',"data":[['Статус', 'Кол-во текущий год', 'Кол-во предыдущий год'] ].concat( _vm.googleChart.data),"options":_vm.googleChart.options}}):_vm._e()],1)]),_c('div',{staticClass:"google-charts"},[_c('div',{staticClass:"google-charts__item google-charts__item--full-width"},[(_vm.googleChart.planData.length)?_c('GoogleChart',{attrs:{"type":'BarChart',"data":[
            ['Подразделение', 'Факт', 'Остаток', 'Перевыполнение'] ].concat( _vm.googleChart.planData
          ),"options":_vm.googleChart.optionsStack}}):_vm._e()],1)]),_c('div',{staticClass:"google-charts"},[_c('div',{staticClass:"google-charts__item"},[(_vm.googleChart.propertyDataCount.length)?_c('GoogleChart',{attrs:{"type":'PieChart',"data":[['Статус', 'Количество'] ].concat( _vm.googleChart.propertyDataCount),"options":_vm.googleChart.optionsPieCount}}):_vm._e()],1),_c('div',{staticClass:"google-charts__item"},[(_vm.googleChart.propertyDataSumm.length)?_c('GoogleChart',{attrs:{"type":'PieChart',"data":[['Статус', 'Сумма'] ].concat( _vm.googleChart.propertyDataSumm),"options":_vm.googleChart.optionsPieSum}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }