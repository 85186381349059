<template>
  <GChart :type="type" :data="data" :options="options" :settings="{ language: 'ru' }" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'GoogleChart',
  props: ['type', 'data', 'options'],
  components: {
    GChart
  },
  data () {
    return {}
  }
}
</script>
