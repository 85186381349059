<template>
  <main class="home" role="main">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__header">
      <div class="toper__item left"></div>
      <div class="toper__item">
        <div class="button-group">
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'today' }"
            @click="setSortType('today')"
          >
            <span>Сегодня</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'tomorrow' }"
            @click="setSortType('tomorrow')"
          >
            <span>Вчера</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'week' }"
            @click="setSortType('week')"
          >
            <span>Неделя</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'month' }"
            @click="setSortType('month')"
          >
            <span>Месяц</span>
          </button>
          <button
            type="button"
            @click="openPeriod = true"
            :class="{
              active:
                $store.state.data.trades.sort2 === 'period' &&
                $store.state.data.trades.sortDate,
            }"
            ref="buttonPeriod"
            v-click-outside="vcoConfig"
            class="widgets__table__field"
          >
            <i>
              <CalendarIcon />
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker
                v-model="$store.state.data.trades.sortDate"
                ref="pickerPeriod"
                inline
                range
              />
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item right"></div>
    </div>
    <div class="main__header main__header--secondary">
      <div class="tbl">
        <div class="tbl__header" v-if="$store.state.data.trades.showFilter">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="
                  filterDropName.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                "
                placeholder="Сотрудник"
                @input="onUserChange"
                :value="
                  filter.responsible_user_id
                    ? {
                        value: filter.responsible_user_id,
                        label: filterDropName.find(
                          (s) => s.id === filter.responsible_user_id
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="
                  filterDropGroup.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                "
                placeholder="Подразделение"
                @input="onGroupChange"
                :value="
                  filter.group_id
                    ? {
                        value: filter.group_id,
                        label: filterDropGroup.find(
                          (s) => s.id === filter.group_id
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="main__content">
      <div v-if="error" class="messages apollo">
        <div v-if="error" class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      </div>
      <div v-if="isLoading" class="messages apollo">
        <div v-if="isLoading" class="messages__status">Загрузка...</div>
      </div>
      <div class="google-charts" >
        <div class="google-charts__item">
          <!-- <GoogleChart
            v-if="googleChart.pieDataCount.length"
            :type ="'PieChart'"
            :data ="[['Статус', 'Кол-во текущий год'], ...googleChart.pieDataCount]"
            :options = "googleChart.optionsPie"
          /> -->
          <GoogleChart
            v-if="googleChart.dataSumm.length"
            :type ="'BarChart'"
            :data ="[['Статус', 'Сумма текущий год', 'Сумма предыдущий год'], ...googleChart.dataSumm]"
            :options = "googleChart.optionsSumm"
          />
        </div>
        <div class="google-charts__item">
          <GoogleChart
            v-if="googleChart.data.length"
            :type ="'BarChart'"
            :data ="[['Статус', 'Кол-во текущий год', 'Кол-во предыдущий год'], ...googleChart.data]"
            :options = "googleChart.options"
          />
        </div>
      </div>
      <div class="google-charts" >
        <div class="google-charts__item google-charts__item--full-width">
          <GoogleChart
            v-if="googleChart.planData.length"
            :type ="'BarChart'"
            :data ="[
              ['Подразделение', 'Факт', 'Остаток', 'Перевыполнение'],
              ...googleChart.planData
            ]"
            :options = "googleChart.optionsStack"
          />
        </div>
      </div>
      <div class="google-charts" >
        <div class="google-charts__item">
          <GoogleChart
            v-if="googleChart.propertyDataCount.length"
            :type="'PieChart'"
            :data="[['Статус', 'Количество'], ...googleChart.propertyDataCount]"
            :options="googleChart.optionsPieCount"
          />
        </div>
        <div class="google-charts__item">
          <GoogleChart
            v-if="googleChart.propertyDataSumm.length"
            :type="'PieChart'"
            :data="[['Статус', 'Сумма'], ...googleChart.propertyDataSumm]"
            :options="googleChart.optionsPieSum"
          />
        </div>
      </div>
        <!-- <div class="google-charts__item">
          <GoogleChart
            v-if="googleChart.pieDataSumm.length"
            :type ="'PieChart'"
            :data ="[['Статус', 'Сумма текущий год'], ...googleChart.pieDataSumm]"
            :options = "googleChart.optionsPie"
          />
        </div>
        <div class="google-charts__item">
          <GoogleChart
            v-if="googleChart.dataSumm.length"
            :type ="'BarChart'"
            :data ="[['Статус', 'Сумма текущий год', 'Сумма предыдущий год'], ...googleChart.dataSumm]"
            :options = "googleChart.optionsSumm"
          />
        </div> -->
        <!-- </div> -->
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
import CalendarIcon from '../components/svg/CalendarIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import gql from 'graphql-tag'
import DownIcon from '@/components/svg/DownIcon'

import GoogleChart from '@/components/charts/GoogleChart.vue'

const _moment = extendMoment(moment)
const Today = _moment()
const Yesterday = _moment().subtract(1, 'days')
const NextWeek = _moment().endOf('week').add(1, 'week')

export default {
  name: 'diagrams',
  components: {
    CalendarIcon,
    DownIcon,
    DatePicker,
    GoogleChart
  },
  data () {
    return {
      title: 'Диаграммы',
      isLoading: true,
      error: null,
      filterDropName: [],
      filterDropGroup: [],
      filter: {
        responsible_user_id: null,
        group_id: null
      },
      callCenter: false,
      internet: false,
      openPeriod: false,
      reportData: {},
      sortFilter: {},
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      },
      googleChart: {
        data: [],
        dataSumm: [],
        pieDataCount: [],
        pieDataSumm: [],
        propertyDataCount: [],
        propertyDataSumm: [],
        planData: [],
        options: {
          title: 'Статистика по количеству',
          chartArea: { width: '50%' },
          hAxis: {
            title: 'Кол-во',
            format: '#',
            minValue: 0
          },
          vAxis: {
            title: 'Статус',
            gridlines: { count: 10 }
          },
          // orientation: 'horizontal',
          width: '80%',
          legend: { position: 'bottom' },
          height: 600
        },

        optionsSumm: {
          title: 'Статистика по сумме',
          chartArea: { width: '50%' },
          hAxis: {
            title: 'Сумма',
            format: 'short',
            minValue: 0
          },
          vAxis: {
            title: 'Статус'
          },
          width: '80%',
          legend: { position: 'bottom' },
          height: 600
        },

        optionsStack: {
          title: 'План/Факт',
          chartArea: { width: '80%' },
          hAxis: {
            title: 'Подразделения',
            format: 'short',
            minValue: 0
          },
          vAxis: {
            title: 'Сумма',
            format: 'short'
          },
          isStacked: true,
          orientation: 'horizontal',
          language: 'ru',
          width: '80%',
          legend: { position: 'bottom' },
          height: 600
        },

        optionsPieCount: {
          title: 'Статистика по имуществу (кол-во)',
          pieHole: 0.4,
          width: '100%',
          height: 600
        },
        optionsPieSum: {
          title: 'Статистика по имуществу (сумма)',
          pieHole: 0.4,
          width: '100%',
          height: 600
        }
      }
    }
  },
  watch: {
    '$store.state.data.trades.sortDate' () {
      if (this.$store.state.data.trades.sortDate) {
        this.$store.state.data.trades.sort2 = 'period'
      } else {
        this.$store.state.data.trades.sort2 = null
      }
      this.openPeriod = false
      this.isLoading = true
    }
  },
  computed: {
    totals () {
      if (!Array.isArray(this.reportData)) return {}
      const res = {}
      this.$store.state.data.analytics.columns.filter(el => el.type === 'number').map(el => {
        res[el.field] = 0
      })
      this.reportData.reduce((acc, ent) => {
        for (const el in acc) {
          if (ent[el]) acc[el] += +ent[el]
        }
        return acc
      }, res)
      res.convLeadProject = res.leadsCount === 0 ? 0 : res.projectCount / res.leadsCount
      res.convProjectFact = res.projectCount === 0 ? 0 : res.factCount / res.projectCount
      res.convLeadFact = res.leadsCount === 0 ? 0 : res.factCount / res.leadsCount
      res.convSLeadProject = res.leadsSumm === 0 ? 0 : res.projectSumm / res.leadsSumm
      res.convSProjectFact = res.projectSumm === 0 ? 0 : res.factSumm / res.projectSumm
      res.convSLeadFact = res.leadsSumm === 0 ? 0 : res.factSumm / res.leadsSumm

      for (const el in res) {
        if (!el.includes('conv')) {
          res[el] = this.prettifyPrice(res[el])
        }
      }
      return res
    },
    getPeriodFilter () {
      switch (this.$store.state.data.trades.sort2) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today)
              .startOf('day')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Yesterday).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('week').format('YYYY-MM-DD'),
            end: _moment(Today)
              .endOf('week')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'next_week':
          return {
            start: _moment(NextWeek).startOf('week').format('YYYY-MM-DD'),
            end: _moment(NextWeek)
              .endOf('week')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today)
              .endOf('month')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'after_next_week':
          return {
            start: _moment(NextWeek).endOf('week').format('YYYY-MM-DD')
          }
        case 'expired':
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.trades.sortDate[0])
              .startOf('day')
              .format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.trades.sortDate[1])
              .endOf('day')
              .format('YYYY-MM-DD')
          }
        default:
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
      }
    },
    getApolloFilter () {
      let filter = {}
      filter.responsible_user_id = this.filter.responsible_user_id
      filter.group_id = this.filter.group_id
      if (this.callCenter) filter.callCenter = true
      if (this.internet) filter.internet = true
      return filter
    }
  },
  apollo: {
    reportData () {
      this.error = null
      this.isLoading = true
      try {
        return {
          query: gql`
            query($sort: PeriodFilter, $filter: JSON, $sortAlter: PeriodFilter) {
              getReport: getReport(sort: $sort, filter: $filter, analytic: true)
              getAlterReport: getReport(sort: $sortAlter, filter: $filter, analytic: true)
              getPropertyReport: getPropertyReport(sort: $sort, filter: $filter)
            }
          `,
          variables () {
            return {
              sort: this.getPeriodFilter,
              sortAlter: this.dateLastYear(this.getPeriodFilter),
              filter: this.getApolloFilter
            }
          },
          update: (data) => {
            this.googleChart.planData = []
            if (!this.filter.responsible_user_id) {
              this.filterDropName = data.getReport
                .filter((ent) => ent.user_id)
                .map((ent) => ({ name: ent.name, id: ent.user_id }))
            }
            if (!this.filter.group_id) {
              const res = {}
              data.getReport.forEach(el => {
                if (!res[el.group_id] && el.groupname) res[el.group_id] = { name: el.groupname, id: el.group_id }
              })
              data.getAlterReport.forEach(el => {
                if (!res[el.group_id] && el.groupname) res[el.group_id] = { name: el.groupname, id: el.group_id }
              })
              this.filterDropGroup = Object.values(res)
              // this.filterDropGroup.push({
              //   name: 'Филиалы',
              //   id: [
              //     '57a91d60-1c2e-11e2-8296-00304866b623',
              //     'dee2beee-4aac-11de-a104-00304866b623',
              //     '74cb0892-681c-11e9-bb4f-bcee7be31495',
              //     '1814a48e-afa1-11ea-80bf-00259047d1e4',
              //     'e5786ff6-4aac-11de-a104-00304866b623',
              //     '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
              //     'e578711c-4aac-11de-a104-00304866b623',
              //     'e57870a3-4aac-11de-a104-00304866b623',
              //     '9493c05d-4e93-11de-866b-001b38e49421',
              //     'e5787009-4aac-11de-a104-00304866b623',
              //     'dc096dea-feb3-11ec-80c9-00259047d1e4',
              //     '936c25c0-835c-11ec-80c6-00259047d1e4']
              // })
              // this.filterDropGroup.push({
              //   name: 'Филиалы Татарстан',
              //   id: [
              //     '57a91d60-1c2e-11e2-8296-00304866b623',
              //     'dee2beee-4aac-11de-a104-00304866b623',
              //     'e5787009-4aac-11de-a104-00304866b623',
              //     '936c25c0-835c-11ec-80c6-00259047d1e4',
              //     'c678408e-8ff2-11ed-80c9-00259047d1e4'
              //   ]
              // })
              // this.filterDropGroup.push({
              //   name: 'Филиалы не Татарстан',
              //   id: [
              //     '74cb0892-681c-11e9-bb4f-bcee7be31495',
              //     '1814a48e-afa1-11ea-80bf-00259047d1e4',
              //     'e5786ff6-4aac-11de-a104-00304866b623',
              //     '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
              //     'e578711c-4aac-11de-a104-00304866b623',
              //     'e57870a3-4aac-11de-a104-00304866b623',
              //     '9493c05d-4e93-11de-866b-001b38e49421',
              //     'dc096dea-feb3-11ec-80c9-00259047d1e4']
              // })
              // this.filterDropGroup.push({
              //   name: 'Казань офис продаж',
              //   id: ['245be5c7-26b6-11e9-bb4f-bcee7be31495', '32a431c7-26b6-11e9-bb4f-bcee7be31495']
              // })
            }
            this.$nextTick(() => {
              this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
            })
            let leadsCount = [0]
            let projectCount = [0]
            let decisionCount = [0]
            let approvedCount = [0]
            let factCount = [0]

            let leadsSumm = [0]
            let projectSumm = [0]
            let decisionSumm = [0]
            let approvedSumm = [0]
            let factSumm = [0]

            let leadsCount2 = [0]
            let projectCount2 = [0]
            let decisionCount2 = [0]
            let approvedCount2 = [0]
            let factCount2 = [0]

            let leadsSumm2 = [0]
            let projectSumm2 = [0]
            let decisionSumm2 = [0]
            let approvedSumm2 = [0]
            let factSumm2 = [0]

            // Техника

            let equipmentCount = [0]
            let lightCount = [0]
            let commercialCount = [0]
            let specialCount = [0]
            let realEstateCount = [0]
            let lightCommercialCount = [0]
            let residentialEstateCount = [0]
            let landplotCount = [0]

            let equipmentSumm = [0]
            let lightSumm = [0]
            let commercialSumm = [0]
            let specialSumm = [0]
            let realEstateSumm = [0]
            let lightCommercialSumm = [0]
            let residentialEstateSumm = [0]
            let landplotSumm = [0]

            data.getReport.forEach(elem => {
              if (elem.leadsCount) leadsCount.push(elem.leadsCount)
              if (elem.projectCount) projectCount.push(elem.projectCount)
              if (elem.decisionCount) decisionCount.push(elem.decisionCount)
              if (elem.approvedCount) approvedCount.push(elem.approvedCount)
              if (elem.factCount) factCount.push(elem.factCount)

              if (elem.leadsSumm) leadsSumm.push(elem.leadsSumm)
              if (elem.projectSumm) projectSumm.push(elem.projectSumm)
              if (elem.decisionSumm) decisionSumm.push(elem.decisionSumm)
              if (elem.approvedSumm) approvedSumm.push(elem.approvedSumm)
              if (elem.factSumm) factSumm.push(elem.factSumm)
            })

            data.getAlterReport.forEach(elem => {
              if (elem.leadsCount) leadsCount2.push(elem.leadsCount)
              if (elem.projectCount) projectCount2.push(elem.projectCount)
              if (elem.decisionCount) decisionCount2.push(elem.decisionCount)
              if (elem.approvedCount) approvedCount2.push(elem.approvedCount)
              if (elem.factCount) factCount2.push(elem.factCount)

              if (elem.leadsSumm) leadsSumm2.push(elem.leadsSumm)
              if (elem.projectSumm) projectSumm2.push(elem.projectSumm)
              if (elem.decisionSumm) decisionSumm2.push(elem.decisionSumm)
              if (elem.approvedSumm) approvedSumm2.push(elem.approvedSumm)
              if (elem.factSumm) factSumm2.push(elem.factSumm)
            })

            data.getPropertyReport.forEach(elem => {
              if (elem.equipmentCount) equipmentCount.push(+elem.equipmentCount)
              if (elem.lightCount) lightCount.push(+elem.lightCount)
              if (elem.commercialCount) commercialCount.push(+elem.commercialCount)
              if (elem.specialCount) specialCount.push(+elem.specialCount)
              if (elem.realEstateCount) realEstateCount.push(+elem.realEstateCount)
              if (elem.lightCommercialCount) lightCommercialCount.push(+elem.lightCommercialCount)
              if (elem.residentialEstateCount) residentialEstateCount.push(+elem.residentialEstateCount)
              if (elem.landplotCount) landplotCount.push(+elem.landplotCount)

              if (elem.equipmentSumm) equipmentSumm.push(+elem.equipmentSumm)
              if (elem.lightSumm) lightSumm.push(+elem.lightSumm)
              if (elem.commercialSumm) commercialSumm.push(+elem.commercialSumm)
              if (elem.specialSumm) specialSumm.push(+elem.specialSumm)
              if (elem.realEstateSumm) realEstateSumm.push(+elem.realEstateSumm)
              if (elem.lightCommercialSumm) lightCommercialSumm.push(+elem.lightCommercialSumm)
              if (elem.residentialEstateSumm) residentialEstateSumm.push(+elem.residentialEstateSumm)
              if (elem.landplotSumm) landplotSumm.push(+elem.landplotSumm)
            })

            this.googleChart.data = [
              ['Всего заявок', leadsCount.reduce((a, b) => a + b), leadsCount2.reduce((a, b) => a + b)],
              ['Создание', projectCount.reduce((a, b) => (+a) + (+b)), projectCount2.reduce((a, b) => (+a) + (+b))],
              ['Рассмотрение', decisionCount.reduce((a, b) => (+a) + (+b)), decisionCount2.reduce((a, b) => (+a) + (+b))],
              ['Одобрено', approvedCount.reduce((a, b) => (+a) + (+b)), approvedCount2.reduce((a, b) => (+a) + (+b))],
              ['Передано в лизинг', factCount.reduce((a, b) => (+a) + (+b)), factCount2.reduce((a, b) => (+a) + (+b))]
            ]

            this.googleChart.dataSumm = [
              // ['Всего заявок', leadsSumm.reduce((a, b) => a + b), leadsSumm2.reduce((a, b) => a + b)],
              ['Создание', projectSumm.reduce((a, b) => (+a) + (+b)), projectSumm2.reduce((a, b) => (+a) + (+b))],
              ['Рассмотрение', decisionSumm.reduce((a, b) => (+a) + (+b)), decisionSumm2.reduce((a, b) => (+a) + (+b))],
              ['Одобрено', approvedSumm.reduce((a, b) => (+a) + (+b)), approvedSumm2.reduce((a, b) => (+a) + (+b))],
              ['Передано в лизинг', factSumm.reduce((a, b) => (+a) + (+b)), factSumm2.reduce((a, b) => (+a) + (+b))]
            ]

            const otherCount = leadsCount.reduce((a, b) => a + b) - (projectCount.reduce((a, b) => (+a) + (+b)) + decisionCount.reduce((a, b) => (+a) + (+b)) + approvedCount.reduce((a, b) => (+a) + (+b)) + factCount.reduce((a, b) => (+a) + (+b)))

            this.googleChart.pieDataCount = [
              ['Создание', projectCount.reduce((a, b) => (+a) + (+b))],
              ['Рассмотрение', decisionCount.reduce((a, b) => (+a) + (+b))],
              ['Одобрено', approvedCount.reduce((a, b) => (+a) + (+b))],
              ['Передано в лизинг', factCount.reduce((a, b) => (+a) + (+b))],
              ['Другие', otherCount]
            ]

            this.googleChart.pieDataSumm = [
              ['Создание', projectSumm.reduce((a, b) => (+a) + (+b))],
              ['Рассмотрение', decisionSumm.reduce((a, b) => (+a) + (+b))],
              ['Одобрено', approvedSumm.reduce((a, b) => (+a) + (+b))],
              ['Передано в лизинг', factSumm.reduce((a, b) => (+a) + (+b))]
            ]

            this.googleChart.propertyDataCount = [
              ['Оборудование', equipmentCount.reduce((a, b) => (+a) + (+b))],
              ['Легковой автотранспорт', lightCount.reduce((a, b) => (+a) + (+b))],
              ['Коммерческий автотранспорт', commercialCount.reduce((a, b) => (+a) + (+b))],
              ['Спецтехника', specialCount.reduce((a, b) => (+a) + (+b))],
              ['Недвижимость', realEstateCount.reduce((a, b) => (+a) + (+b))],
              ['Легкий коммерческий автотранспорт', lightCommercialCount.reduce((a, b) => (+a) + (+b))],
              ['Недвижимость жилая', residentialEstateCount.reduce((a, b) => (+a) + (+b))],
              ['Земля', landplotCount.reduce((a, b) => (+a) + (+b))]
            ]

            this.googleChart.propertyDataSumm = [
              ['Оборудование', equipmentSumm.reduce((a, b) => (+a) + (+b))],
              ['Легковой автотранспорт', lightSumm.reduce((a, b) => (+a) + (+b))],
              ['Коммерческий автотранспорт', commercialSumm.reduce((a, b) => (+a) + (+b))],
              ['Спецтехника', specialSumm.reduce((a, b) => (+a) + (+b))],
              ['Недвижимость', realEstateSumm.reduce((a, b) => (+a) + (+b))],
              ['Легкий коммерческий автотранспорт', lightCommercialSumm.reduce((a, b) => (+a) + (+b))],
              ['Недвижимость жилая', residentialEstateSumm.reduce((a, b) => (+a) + (+b))],
              ['Земля', landplotSumm.reduce((a, b) => (+a) + (+b))]
            ]

            // const planData = []

            // planData.push(['Подразделение', 'План', 'Остаток'])

            // this.filterDropGroup.forEach(group => {
            //   const usGr = []
            //   data.getReport.forEach(user => {
            //     const temp = group.id.filter(elem => elem === user.group_id)
            //     if (temp.length) {
            //       usGr.push(user)
            //     }
            //   })
            //   planData.push([usGr[0].groupname, usGr[0].plan, +usGr[0].plan - +usGr[0].factSumm])
            // })

            // this.planData = planData

            let groups = [...new Set(this.filterDropGroup)]

            groups = groups.map(elem => ({
              ...elem, plan: 0, ost: 0
            }))

            groups.forEach((group, index) => {
              const filterData = data.getReport.filter(user => group.id.includes(user.group_id))
              if (filterData.length) {
                let overfulfillment = 0
                const mapUsers = filterData.map(elem => (elem.plan || 0))
                groups[index].plan = mapUsers.reduce((a, b) => (+a) + (+b))
                const mapUsers2 = filterData.map(elem => (elem.factSumm || 0))
                groups[index].ost = groups[index].plan - mapUsers2.reduce((a, b) => (+a) + (+b))
                if (groups[index].ost < 0) {
                  overfulfillment = -1 * groups[index].ost
                  groups[index].ost = 0
                }
                groups[index].ost = groups[index].ost < 0 ? 0 : groups[index].ost
                this.googleChart.planData.push([groups[index].name, groups[index].plan, groups[index].ost, overfulfillment])
              }
            })

            this.isLoading = false
            return data.getReport
          }
        }
      } catch (e) {
        this.isLoading = false
        this.error = e
      }
    }
  },
  methods: {
    dateLastYear (period) {
      const lastPeriod = {
        start: null,
        end: null
      }
      // lastPeriod.start = new Date(new Date(period.start).setFullYear(new Date(period.start).getFullYear() - 1)).toLocaleString().split('T')[0]
      // lastPeriod.end = new Date(new Date(period.end).setFullYear(new Date(period.end).getFullYear() - 1)).toLocaleString().split('T')[0]

      lastPeriod.start = `${period.start.split('-')[0] - 1}-${period.start.split('-')[1]}-${period.start.split('-')[2]}`
      lastPeriod.end = `${period.end.split('-')[0] - 1}-${period.end.split('-')[1]}-${period.end.split('-')[2]}`

      return lastPeriod
    },
    setSortType (type) {
      if (this.$store.state.data.trades.sort2 === type) {
        this.$store.state.data.trades.sort2 = null
      } else {
        this.$store.state.data.trades.sort2 = type
      }
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    onUserChange (e) {
      if (e) {
        this.filter.responsible_user_id = e.value
      } else {
        this.filter.responsible_user_id = null
      }
      this.isLoading = true
    },
    onGroupChange (e) {
      if (e) {
        this.filter.group_id = e.value
      } else {
        this.filter.group_id = null
      }
      this.isLoading = true
    },
    switchColumnModal () {
      this.$store.state.data.analytics.columnModal = !this.$store.state.data.analytics.columnModal
    }
  }
}
</script>

<style lang="stylus" scoped>
  .main__header--secondary {
    grid-template-columns 1fr !important
  }
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 40px
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;

  .google-charts
    display: flex
    flex-direction: row
    justify-content: space-between
    flex-wrap: wrap

    &__item
      width: 100%
      min-width: 350px
      max-width: 50%

      +below(860px) {
        max-width: 100%
      }

      &--full-width {
        min-width: none
        max-width: none
      }
</style>
